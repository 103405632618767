import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CornellBrainDiagramImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "images/cornell-brain-diagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <Img style={{maxWidth: '400px'}} fluid={data.placeholderImage.childImageSharp.fluid} alt={'Diagrams showing brain activity. Diagram A shows a brain where the person is using a blank lens. Over-activity in the brain is represented by lots of red marks. Diagram B shows a brain where the person is using a spectral filter. Much less over-activity can be seen.'} />
}

export default CornellBrainDiagramImage
