import React, { useState } from "react"
import "./HiddenObjectsTest.scss"
import HiddenObjectsCaterpillarImage from "../HiddenObjectsCaterpillarImage"
import ColorPicker from "../ColorPicker/ColorPicker"
import HiddenObjectsRaccoonImage from "../HiddenObjectsRaccoonImage"
import HiddenObjectsBearImage from "../HiddenObjectsBearImage"
import Button from "../Button/Button"

export type HiddenObjectsTestProps = {
}

const HiddenObjectsTest = ({}: HiddenObjectsTestProps) => {
  const [backgroundColor, setBackgroundColor] = useState('');

  const tests = [
    {
      name: 'Caterpillar',
      component: HiddenObjectsCaterpillarImage
    },
    {
      name: 'Raccoon',
      component: HiddenObjectsRaccoonImage
    },
    {
      name: 'Bear',
      component: HiddenObjectsBearImage
    }
  ];

  const [test, setTest] = useState(tests[0]);

  return (
    <div className="hidden-objects-test">
      <div className={"hidden-objects-test__colors"}>
        <ColorPicker label={"Click to change:"} onColorSelect={setBackgroundColor} />
      </div>
      <div className={'hidden-objects-test__test-names'}>
        {tests.map(x => (
          <Button key={x.name} onClick={() => setTest(x)}>{x.name}</Button>
        ))}
      </div>
      <div className={"hidden-objects-test__image"} style={{backgroundColor, transition: "0.3s"}}>
        {tests.map(x => (
          <div key={x.name} hidden={x.name !== test.name}>
            {React.createElement(test.component)}
          </div>
        ))}
      </div>
      <p>
        Images courtesy of <a href={'http://hiddenpicturepuzzles.com/'} target={'_blank'} rel={'noopener noreferer'}>http://hiddenpicturepuzzles.com/</a>
      </p>
    </div>
  )
}

export default HiddenObjectsTest
