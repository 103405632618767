import React from "react"
import "./ClientTile.scss"
import { Link } from "gatsby"
import BookIconImage from "../BookIconImage"
import LazyLoad from "react-lazyload";

export type ClientTileProps = {
  slug: string;
  imageUrl: string;
  title: string;
  subtitle: string;
  description: string;
}

const ClientTile = ({ slug, imageUrl, title, subtitle, description }: ClientTileProps) => (
  <div className="client-tile">
    <LazyLoad height={500} once>
      <Link to={slug}>
        <div className="client-tile__image">
          <img src={imageUrl} alt={`${title}, ${subtitle}`} />
        </div>
        <div className="client-tile__content">
          <div className="client-tile__title">
            {title}
          </div>
          <div className="client-tile__subtitle">
            {subtitle}
          </div>
          <div className="client-tile__description-and-actions">
            <div className="client-tile__description">
              {description}
            </div>
            <div className="client-tile__actions">
              <div className="client-tile__book-icon">
                <BookIconImage />
              </div>
              <div>
                Read Post
              </div>
            </div>
          </div>
        </div>
      </Link>
    </LazyLoad>
  </div>
)

export default ClientTile
