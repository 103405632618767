import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from "../components/Container/Container"
import TwoColumns from "../components/TwoColumns/TwoColumns"
import KristSamaritanIrlenClinicImage from "../components/KristSamaritanIrlenClinicImage"
import TextAndImage from "../components/TextAndImage/TextAndImage"
import LinkButton from "../components/LinkButton/LinkButton"
import ThreeColumns from "../components/ThreeColumns/ThreeColumns"
import Tile from "../components/Tile/Tile"
import FactTile from "../components/FactTile/FactTile"
import PurpleBrainImage from "../components/PurpleBrainImage"
import BrainSymptoms from "../components/BrainSymptoms/BrainSymptoms"
import ClientsGrid from "../components/ClientsGrid/ClientsGrid"
import DirectorInIrlenGlassesImage from "../components/DirectorInIrlenGlassesImage"
import Video from "../components/Video/Video"
import OneColumn from "../components/OneColumn/OneColumn"
import Card from "../components/Card/Card"
import ClientWithOverlayImage from "../components/ClientWithOverlayImage"
import ClientWithLensesBeforeAndAfter from "../components/ClientWithLensesBeforeAndAfter"
import VerticalConnector from "../components/VerticalConnector/VerticalConnector"
import GirlInIrlenGlassesWithDogImage from "../components/GirlInIrlenGlassesWithDogImage"
import ClientWithLensesImage from "../components/ClientWithLensesImage"
import Form from "../components/Form/Form"
import TextInput from "../components/TextInput/TextInput"
import SubmitButton from "../components/SubmitButton/SubmitButton"
import HiddenObjectsTest from "../components/HiddenObjectsTest/HiddenObjectsTest"
import Button from "../components/Button/Button"
import ColorTest from "../components/ColorTest/ColorTest"
import YesNoQuestionnaire from "../components/YesNoQuestionnaire/YesNoQuestionnaire"
import ScreenReaderOnly from "../components/ScreenReaderOnly/ScreenReaderOnly"
import KristSamaritanCenterLogoImage from "../components/KristSamaritanCenterLogoImage"
import HandwrittenSymptomsImage from "../components/HandwrittenSymptomsImage"
import CornellBrainDiagramImage from "../components/CornellBrainDiagramImage"
import LazyLoad from "react-lazyload";
import NetlifyReCaptcha from "../components/NetlifyReCaptcha";

export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
      }
    }
  }
`

type PageTemplateProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        slug: string;
      };
      body: string;
    };
  };
}

const shortcodes = {
  Link,
  LinkButton,
  Container,
  Tile,
  FactTile,
  TwoColumns,
  TextAndImage,
  ThreeColumns,
  KristSamaritanIrlenClinicImage,
  PurpleBrainImage,
  BrainSymptoms,
  ClientsGrid,
  DirectorInIrlenGlassesImage,
  Video,
  OneColumn,
  Card,
  ClientWithOverlayImage,
  ClientWithLensesBeforeAndAfter,
  VerticalConnector,
  GirlInIrlenGlassesWithDogImage,
  ClientWithLensesImage,
  Form,
  TextInput,
  SubmitButton,
  HiddenObjectsTest,
  Button,
  ColorTest,
  YesNoQuestionnaire,
  ScreenReaderOnly,
  KristSamaritanCenterLogoImage,
  HandwrittenSymptomsImage,
  CornellBrainDiagramImage,
  LazyLoad,
  NetlifyReCaptcha,
}

export default function PageTemplate({ data: { mdx } }: PageTemplateProps) {
  return (
    <Layout slug={mdx.frontmatter.slug}>
      <Seo title={mdx.frontmatter.title} />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}