import React from "react"
import "./Video.scss"

export type VideoProps = {
  youtubeCode: string;
  width?: number;
  caption?: string;
}

const Video = ({ youtubeCode, width = 640, caption = undefined }: VideoProps) => (
  <div style={{maxWidth: `${width}px`}} className="video">
    <div className="video__iframe-container">
      <iframe className="video__iframe"
              title={caption ? caption : 'YouTube video'}
              src={`https://www.youtube.com/embed/${youtubeCode}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
    </div>
    {caption ? <p className="video__caption">{caption}</p> : ''}
  </div>
)

export default Video
