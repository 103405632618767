import React, { ComponentProps } from "react"
import "./Button.scss"

export interface ButtonProps extends ComponentProps<any> {
  onClick: () => void;
  tabIndex?: number;
  disabled?: boolean
}

const Button = ({ children, onClick, tabIndex, disabled = false }: ButtonProps) => (
  <button className="button" onClick={onClick} tabIndex={tabIndex} disabled={disabled}>
    {children}
  </button>
)

export default Button
