import React from "react"
import "./ClientsGrid.scss"
import { graphql, useStaticQuery } from "gatsby"
import ClientTile from "../ClientTile/ClientTile"

export type ClientsGridProps = {
  limit?: number;
}

const ClientsGrid = ({ limit = 36 }: ClientsGridProps) => {
  const data = useStaticQuery(graphql`
      query ClientsGridQuery {
          allMdx(filter: { fields: { contentType: { eq: "client" } } }) {
              nodes {
                  fields {
                      slug
                  }
                  frontmatter {
                      title
                      tileSubtitle
                      tileDescription
                      imageUrl
                      order
                  }
              }
          }
      }
  `)

  const clients = data.allMdx.nodes.map(node => {
    return {
      slug: node.fields.slug,
      title: node.frontmatter.title,
      subtitle: node.frontmatter.tileSubtitle,
      description: node.frontmatter.tileDescription,
      imageUrl: node.frontmatter.imageUrl,
      order: node.frontmatter.order
    }
  }).sort((x, y) => x.order - y.order).slice(0, limit);

  return (
    <div className="clients-grid">
      {clients.map(client => <ClientTile {...client} key={client.slug} />)}
    </div>
  )
}

export default ClientsGrid
