import React from "react"
import "./BrainSymptoms.scss"
import PurpleBrainImage from "../PurpleBrainImage"

export type BrainSymptomsProps = {
  prop: string;
}

const BrainSymptoms = ({ prop }: BrainSymptomsProps) => (
  <div className="brain-symptoms">
    <div>
      <PurpleBrainImage />
    </div>
    <div className="brain-symptoms__label-1">
      <div>
        Headaches & Migraines
      </div>
    </div>
    <div className="brain-symptoms__label-2">
      <div>
        Depth Perception
      </div>
    </div>
    <div className="brain-symptoms__label-3">
      <div>
        Anxiety & Depression
      </div>
    </div>
  </div>
)

export default BrainSymptoms
