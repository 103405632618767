import React from "react"
import "./VerticalConnector.scss"

const VerticalConnector = () => (
  <div className="vertical-connector">
    <div className="vertical-connector__left" />
    <div className="vertical-connector__right" />
  </div>
)

export default VerticalConnector
