import React, { useState } from "react"
import "./ColorTest.scss"
import ColorPicker from "../ColorPicker/ColorPicker"
import Button from "../Button/Button"
import Tile from "../Tile/Tile"

export type ColorTestProps = {
}

const ColorTest = ({}: ColorTestProps) => {
  const [background, setBackground] = useState('linear-gradient(to right, #fff 50%, rgb(191, 149, 223) 50%)');

  const tests = [
    {
      name: 'Adult',
      content: (
        <div className={'color-test__adult-text'}>
          <p>
            People with Irlen Syndrome are sensitive to color. They usually can find a color that makes reading easier than when text is on a white page. They can also find particular colors worse than a white page!
          </p>
          <p>
            Please note this self-test just provides an indication of whether you are suffering from Irlen Syndrome, a full screening will be necessary to determine your particular difficulties when reading, and to find a particular color of overlay (clear plastic sheet) to help alleviate them. The color self-chosen by this test is unlikely to be the same color as your required plastic overlay or tinted glasses, as light works in different ways with regard to lenses and overlays.
          </p>
          <p>
            People with Irlen Syndrome find that black text on a white page is not comfortable to look at. Reading can make them tired and stressed. Words may get blurry, move or distort in another way. They often lose their place, and may use a marker or their finger to try to stop this from happening. Reading is often slow, and they may need to go back and re-read to try to understand the passage. Some people get headaches when reading, or can feel dizzy.
          </p>
        </div>
      )
    },
    {
      name: 'Child',
      content: (
        <div className={'color-test__child-text'}>
          jfum    loiw    msd    kljgu   opi    qe    qwe    rty    ui    op    asdf    gh    j    klz    xcv    bncq   jfum    loiw    msd    kljgu    opi    qe    qwe    rty    ui    op    asdf    gh    j    klz    xcv    bncq    jfum    loiw    msd    kljgu    opi    qe    qwe    rty    ui    op    asdf    gh    j    klz    xcv    bncq    jfum    loiw    msd
        </div>
      )
    }
  ];

  const [test, setTest] = useState(tests[0]);

  return (
    <div className="color-test">
      <div className={"color-test__colors"}>
        <ColorPicker label={"Click to change:"} onColorSelect={(color) => setBackground(`linear-gradient(to right, #fff 50%, ${color} 50%)`)} />
      </div>
      <div className={'color-test__test-names'}>
        {tests.map(x => (
          <Button key={x.name} onClick={() => setTest(x)}>{x.name}</Button>
        ))}
      </div>
      <div className={"color-test__text"}>
        <Tile style={{background}}>
          {tests.map(x => (
            <div key={x.name} hidden={x.name !== test.name}>
              {test.content}
            </div>
          ))}
        </Tile>
      </div>
    </div>
  )
}

export default ColorTest
