import "./Tile.scss"
import React from "react"

export interface TileProps extends React.HTMLProps<HTMLDivElement> {
  label?: string;
  padding?: 'narrow' | 'padded';
}

const Tile = ({ children, label, padding = 'narrow', style }: TileProps) => (
  <div className={`tile tile--padding-${padding}`} style={style}>
    {label && <div className={`tile__label`}>{label}</div>}
    {children}
  </div>
)

export default Tile
