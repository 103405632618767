import React from "react"
import "./Form.scss"

export interface FormProps extends React.HTMLProps<HTMLDivElement> {
  name: string;
}

const Form = ({ children, name }: FormProps) => {
  return (
    <div className="form">
      <form name={name} method="POST"
            data-netlify-honeypot={"bot-field"}
            data-netlify={"asd"}
      >
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="contact" />
        {children}
      </form>
    </div>
  )
}

export default Form
