import React from "react"
import "./FactTile.scss"
import Tile from "../Tile/Tile"

export type FactTileProps = {
  title?: string;
  figure?: string;
  text?: string;
}

const FactTile = ({ title, figure, text }: FactTileProps) => (
  <div className="fact-tile">
    <Tile>
      {title !== undefined && (<h2 className="fact-tile__title">
        {title}
      </h2>)}
      {figure !== undefined && (<div className="fact-tile__figure">
        {figure}
      </div>)}
      {text !== undefined && (<div className="fact-tile__text">
        {text}
      </div>)}
    </Tile>
  </div>
)

export default FactTile
