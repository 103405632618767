import React from "react"
import "./OneColumn.scss"

export interface OneColumnProps extends React.HTMLProps<HTMLDivElement> {
  width?: 'shrink' | 'full';
}

const OneColumn = ({ children, width = 'shrink' }: OneColumnProps) => (
  <div className={`one-column one-column--width-${width}`}>
    <div className="one-column__column">
      {children}
    </div>
  </div>
)

export default OneColumn
