import React, { CSSProperties, ReactNode } from "react"
import "./TwoColumns.scss"

export type TwoColumnsProps = {
  columnOne: ReactNode;
  columnTwo: ReactNode;
  columnOneStyle?: CSSProperties;
  columnTwoStyle?: CSSProperties;
  width?: 'shrink' | 'full';
}

const TwoColumns = ({ columnOne, columnTwo, columnOneStyle = {}, columnTwoStyle = {}, width = 'shrink' }: TwoColumnsProps) => (
  <div className={`two-columns two-columns--width-${width}`}>
    <div style={columnOneStyle}>
      {columnOne}
    </div>
    <div style={columnTwoStyle}>
      {columnTwo}
    </div>
  </div>
)

export default TwoColumns
