import React, { ReactNode } from "react"
import "./TextAndImage.scss"
import TwoColumns from "../TwoColumns/TwoColumns"
import Container from "../Container/Container"

export type TextAndImageProps = {
  text: ReactNode;
  image: ReactNode;
}

const TextAndImage = ({ text, image }: TextAndImageProps) => (
  <div className="text-and-image">
    <div className="text-and-image--side-by-side">
      <Container padding="section">
        <TwoColumns columnOne={text} columnTwo={image}  />
      </Container>
    </div>
    <div className="text-and-image--stacked">
      <div className="text-and-image__image">
        <Container padding="vertical-only">
          {image}
        </Container>
      </div>
      <div className="text-and-image__text">
        <Container padding="section">
          {text}
        </Container>
      </div>
    </div>
  </div>
)

export default TextAndImage
