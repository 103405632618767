import React, { ComponentProps } from "react"
import "./SubmitButton.scss"

export interface SubmitButtonProps extends ComponentProps<any> {}

const SubmitButton = ({ children }: SubmitButtonProps) => (
  <p className="submit-button">
    <button type="submit">{children}</button>
  </p>
)

export default SubmitButton
