import React, { ReactNode } from "react"
import "./ThreeColumns.scss"

export type ThreeColumnsProps = {
  columnOne: ReactNode;
  columnTwo: ReactNode;
  columnThree: ReactNode;
}

const ThreeColumns = ({ columnOne, columnTwo, columnThree }: ThreeColumnsProps) => (
  <div className="three-columns">
    <div>
      {columnOne}
    </div>
    <div>
      {columnTwo}
    </div>
    <div>
      {columnThree}
    </div>
  </div>
)

export default ThreeColumns
