import React, { ReactNode } from "react"
import "./Card.scss"
import Tile from "../Tile/Tile"

export type CardProps = {
  heading: string;
  subHeading: string;
  image?: ReactNode;
  text: ReactNode;
  removeBottomMargin?: boolean;
}

const Card = ({ heading, subHeading, image, text, removeBottomMargin = false }: CardProps) => (
  <div className={`card ${removeBottomMargin ? 'card--remove-bottom-margin' : ''}`}>
    <Tile>
      <h2 className="card__heading">{heading}</h2>
      <h3 className="card__sub-heading">{subHeading}</h3>
      {image ? <div className="card__image">{image}</div> : ''}
      <div className="card__text">
        {text}
      </div>
    </Tile>
  </div>
)

export default Card
