import React, { ComponentProps } from "react"
import "./LinkButton.scss"
import { Link } from "gatsby"

export interface LinkButtonProps extends ComponentProps<any> {
  to: string;
}

const LinkButton = ({ to, children }: LinkButtonProps) => (
  <p className="link-button">
    <Link to={to}>
      {children}
    </Link>
  </p>
)

export default LinkButton
