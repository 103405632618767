import React, { useState } from "react"
import "./YesNoQuestionnaire.scss"
import Button from "../Button/Button"
import LinkButton from "../LinkButton/LinkButton"

export type YesNoQuestionnaireProps = {
  questions: string[];
  results: Array<{
    threshold: number;
    content: any;
  }>
}

const YesNoQuestionnaire = ({ questions, results }: YesNoQuestionnaireProps) => {
  const [answers, setAnswers] = useState(questions.map(() => false));
  const [questionIndex, setQuestionIndex] = useState(0);
  const [finished, setFinished] = useState(false);

  const answerYes = () => {
    answers[questionIndex] = true;
    setAnswers(answers);
    goForward();
  }

  const answerNo = () => {
    answers[questionIndex] = false;
    setAnswers(answers);
    goForward();
  }

  const goForward = () => {
    if (questionIndex === questions.length - 1) {
      setFinished(true);
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  }

  const goBack = () => {
    setQuestionIndex(Math.max(0, questionIndex - 1));
  }

  const getNumberOfYesses = () => answers.filter(x => x).length

  const getResultContent = () => {
    return results
      .sort((x, y) => y.threshold - x.threshold)
      .find(x => getNumberOfYesses() >= x.threshold)
      ?.content ?? '';
  }

  return (
    <div className="yes-no-questionnaire">
      {finished ? (
        <>
          <div className={'yes-no-questionnaire__result'}>
            <p>You said 'Yes' to {getNumberOfYesses()} out of {questions.length} questions.</p>
            <p>
              {getResultContent()}
            </p>
          </div>
          <LinkButton to={'/self-test'}>All self tests</LinkButton>
        </>
      ) : (
        <>
          <div className={'yes-no-questionnaire__question'}>
            {questions[questionIndex]}
          </div>
          <div className={'yes-no-questionnaire__answers'}>
            <Button onClick={answerYes}>Yes</Button>
            <Button onClick={answerNo}>No</Button>
          </div>
          <div className={'yes-no-questionnaire__position'}>
            {questionIndex + 1 === questions.length ? (
              <>
                Final question
              </>
            ) : (
              <>
                {questions.length - questionIndex} questions remaining
              </>
            )}

          </div>
          <div className={'yes-no-questionnaire__controls'}>
           <Button onClick={goBack} disabled={questionIndex === 0}>Back</Button>
            <Button onClick={goForward}>Skip</Button>
          </div>
        </>
      )}
    </div>
  )
}

export default YesNoQuestionnaire
