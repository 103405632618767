import React from "react"
import "./TextInput.scss"

export type TextInputProps = {
  name: string;
  label: string;
  rows?: number;
}

const TextInput = ({ name, label, rows }: TextInputProps) => (
  <div className="text-input">
    <div className={"text-input__label"}>
      <label htmlFor={name}>{label}</label>
    </div>
    {rows ? (
      <div className={"text-input__input"}>
        <textarea required name={name} rows={rows} />
      </div>
    ) : (
      <div className={"text-input__input"}>
        <input required type={'text'} name={name} />
      </div>
    )}
  </div>
)

export default TextInput
